<template>
    <div class="container-fluid">
        <h1 class="h3 mb-2 text-gray-800">USER DETAILS</h1>
        <!-- Referral code -->
        <div class="card shadow mb-4">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold d-flex" style="justify-content: space-between;">
                    <label class="text-primary">
                        Share Referral Code - <b>{{ customer.referralCode }}</b> <span><i class="fa fa-copy" @click="copyCode(customer.referralCode)"></i></span>
                    </label>
                    <label class="text-primary">
                        Referral Point's: ${{  customer.referralPoints }}
                    </label>
                </h6>
            </div>
            <div class="card-body">
                <p class="mb-3">Share this code with your friends, and when they book a reservation, both of you will receive a $25 credit for a future booking.</p>
                <a href="javascript:void(0)" @click="whatsapp()" class="btn btn-primary btn-icon-split mr-3">
                    <i class="fa fa-whatsapp"></i>
                </a>
                <a href="javascript:void(0)" @click="sendEmail()" class="btn btn-primary btn-icon-split mr-3">
                    <i class="fa fa-envelope"></i>
                </a>
                <a href="javascript:void(0)" @click="sendSMS()" class="btn btn-primary btn-icon-split mr-3">
                    <i class="fa fa-comment"></i>
                </a>
            </div>
        </div>

        <div class="card shadow mb-4">
                    <div class="card-header py-3">
                        <h6 class="m-0 font-weight-bold text-primary">USER PROFILE DETAILS</h6>
                    </div>
                    <div class="card-body">
                        <div class="register-form">
                            <form  method="get" accept-charset="utf-8" @submit.prevent="">
                                <div class="one-half first-name">
                                    <label for="firstname">First Name </label>
                                    <input type="text" name="firstname" id="firstname" v-model.trim="$v.register.regFName.$model" :class="{custErrorBorder: $v.register.regFName.$error}">
                                    <div class="custError" v-if="$v.register.regFName.$error && !$v.register.regFName.required">Field is required</div>
                                    <div class="custError" v-if="!$v.register.regFName.minLength">Name must have at least {{$v.register.regFName.$params.minLength.min}} letters.</div>
                                </div>
                                <div class="one-half last-name">
                                    <label for="lastname">Last Name</label>
                                    <input type="text" name="lastname" id="lastname" v-model.trim="$v.register.regLName.$model" :class="{custErrorBorder: $v.register.regLName.$error}">
                                    <div class="custError" v-if="$v.register.regLName.$error && !$v.register.regLName.required">Field is required</div>
                                    <div class="custError" v-if="!$v.register.regLName.minLength">Name must have at least {{$v.register.regLName.$params.minLength.min}} letters.</div>
                                </div>
                                <div class="one-half phone">
                                    <label for="phone">Phone</label>
                                    <input type="text" name="phone" id="phone" v-model.trim="$v.register.regPhone.$model" :class="{custErrorBorder: $v.register.regPhone.$error}">
                                    <div class="custError" v-if="$v.register.regPhone.$error && !$v.register.regPhone.required">Field is required</div>
                                    <div class="custError" v-if="!$v.register.regPhone.numeric">Enter valid number.</div>
                                    <div class="custError" v-if="!$v.register.regPhone.maxLength">Phone must have at most  {{$v.register.regPhone.$params.maxLength.max}} letters.</div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="alert alert-danger" v-if="registerError">{{registerError}}</div>
                                <div class="alert alert-success" v-if="registerSuccess">{{registerSuccess}}</div>
                            </form>
                            <div class="col-md-12">
                                <div class="btn_sec">
                                    <a href="javascript:void(0)" @click="updateUser()" class="btn btn-primary btn-icon-split mr-3">
                                        <span class="icon text-white-50">
                                            <i class="fa fa-unlock"></i>
                                        </span>
                                        <span class="text">Update</span>
                                    </a>
                                    <router-link :to="{name: 'trips'}" class="btn btn-info btn-icon-split">
                                        <span class="icon text-white-50">
                                            <i class="fa fa-angle-left"></i>
                                        </span>
                                        <span class="text">Back</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

        
    </div>

    
</template>

<script>
import { request } from "../../utils/api";
import { mapGetters } from "vuex";
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/index.css';
import { required, minLength, maxLength, sameAs, email, numeric } from 'vuelidate/lib/validators';
export default {
    components:{
        DatePicker
    },
    data(){
        return {
            tripDetail: '',
            tripId: this.$route.params.tripId,
            pickUpDate: '',
            pickUpTime: '',
            openOnewayTimer: false,
            register: {
                regFName: '',
                regLName: '',
                regPhone: '',
            },
            registerError: '',
            registerSuccess: ''
        }
    },
    computed:{
		...mapGetters(['isLoggedIn', 'customer', 'userId', 'userToken']),        
	},
    validations: {
        register: {
            regFName: {
                required,
                minLength: minLength(2)
            },
            regLName: {
                required,
                minLength: minLength(2)
            },
            regPhone: {
                required,
                numeric,
                maxLength: maxLength(10)
            },
        }
    },
    methods:{
        async updateUser(){
            this.$v.register.$touch();
			if (this.$v.register.$anyError) {
                return;
            }

            const resp = await request({
                method: 'post',
                url: `/api/v1/user/updateprofile`,
                data: {
                        firstName: this.register.regFName,
                        lastName: this.register.regLName,
                        mobileNumber: this.register.regPhone,
                    },
                headers: {
                    authorization: this.userToken,
                    customerid: this.userId
                }
            })
            
            if (resp.remote === "success") {
                this.$store.commit('setUser', resp.data);
                this.registerSuccess = "Details updated";
                
            }else {
                this.registerError = resp.errors;
            }
        },
        async getUser(){
            const resp = await request({
                method: 'get',
                url: `/api/v1/user/profile`,
                headers: {
                    authorization: this.userToken,
                    customerid: this.userId
                }
            })
            if (resp.remote === "success") {
                this.$store.commit('setUser', resp.data);
                this.registerSuccess = "Details updated";
                
            }else {
                this.registerError = resp.errors;
            }
        },
        copyCode(code){
            navigator.clipboard.writeText(code).then(function() {
                alert('Text copied to clipboard');
            }).catch(function(error) {
                console.error('Error copying text: ', error);
            });
        },
        whatsapp(){
            const text = "🎉 Earn $25 for each person upon ride completion with our referral program! 🎉";
            const detailedText = "Sign up using my referral code "+ this.customer.referralCode +" to unlock exclusive benefits! 📲";
            const url = "Click the link and start saving now: https://allblacklimoseattle.com/";
            // Combine all parts into a single message
            const message = `${text}\n\n${detailedText}\n\n${url}`;
            // Encode the message
            const encodedMessage = encodeURIComponent(message);
            // Create the WhatsApp sharing URL
            const whatsappUrl = `https://api.whatsapp.com/send?text=${encodedMessage}`;
            // Open the WhatsApp URL
            window.open(whatsappUrl, '_blank');
        },
        sendEmail() {
            const text = "🎉 Earn $25 for each person upon ride completion with our referral program! 🎉";
            const detailedText = "Sign up using my referral code "+ this.customer.referralCode +" to unlock exclusive benefits! 📲";
            const url = "Click the link and start saving now: https://allblacklimoseattle.com/";
            // Combine all parts into a single message
            const message = `${text}\n\n${detailedText}\n\n${url}`;
            var mailtoLink = "mailto:?subject=All Black Limo - Referral Code&body=" + encodeURIComponent(message);

            // Create a temporary link element
            var link = document.createElement("a");
            link.href = mailtoLink;

            // Append the link to the body (necessary for Firefox)
            document.body.appendChild(link);

            // Programmatically click the link to open the email client
            link.click();

            // Remove the temporary link from the document
            document.body.removeChild(link);
        },
        sendSMS() {
            const text = "🎉 Earn $25 for each person upon ride completion with our referral program! 🎉";
            const detailedText = "Sign up using my referral code "+ this.customer.referralCode +" to unlock exclusive benefits! 📲";
            const url = "Click the link and start saving now: https://allblacklimoseattle.com/";
            // Combine all parts into a single message
            const message = `${text}\n\n${detailedText}\n\n${url}`;

            // Construct the sms: URL
            var smsLink = "sms:?body=" + encodeURIComponent(message);

            // Create a temporary link element
            var link = document.createElement("a");
            link.href = smsLink;

            // Append the link to the body (necessary for Firefox)
            document.body.appendChild(link);

            // Programmatically click the link to open the SMS client
            link.click();

            // Remove the temporary link from the document
            document.body.removeChild(link);
        }
    },
    mounted(){
        this.getUser();
        this.register.regFName = this.customer.firstName;
        this.register.regLName = this.customer.lastName;
        this.register.regPhone = this.customer.mobileNumber;
    }
}
</script>

<style scoped>
.info_wrp >>> .mx-input-wrapper input, .info_wrp >>> .mx-input-wrapper i, .info_wrp >>> .summary-bar input{
    color: #333 !important;
}

</style>